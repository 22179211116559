import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import logo from "./logo.png"; // Logo için
import weddiApp from "./weddi_app.png"; // İlk uygulama ikonu
import refinery from "./refinery.png"; // İkinci uygulama ikonu
import modipic from "./modipic.png"; // Üçüncü uygulama ikonu
import diress from "./diress.png"; // Dördüncü uygulama ikonu
import separai from "./separai.png"; // Beşinci uygulama ikonu
import minipi from "./minipi.png"; // Altıncı uygulama ikonu
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Contact from "./Contact";

function MainContent({ currentYear }) {
  return (
    <div className="container">
      <video autoPlay muted loop className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <h2 className="app-title">
        Our
        <br />
        Applications
      </h2>
      <header className="app-header">
        <div className="apps">
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={refinery} alt="Refinery" className="app-icon refinery" />
            <p>Refinery</p>
          </div>
          <div className="app">
            <div className="badge live-badge">Live</div>
            <img src={weddiApp} alt="Weddi App" className="app-icon" />
            <p>CoupleS.</p>
          </div>
          <div className="app">
            <div className="badge live-badge">Live</div>
            <img src={minipi} alt="Minipi" className="app-icon" />
            <p>Minipi</p>
          </div>
          <div className="app">
            <div className="badge live-badge">Live</div>
            <img src={diress} alt="Diress App" className="app-icon" />
            <p>Diress</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={modipic} alt="Modipic" className="app-icon" />
            <p>Modipic</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={separai} alt="Separai" className="app-icon" />
            <p>Separai</p>
          </div>
        </div>
      </header>

      <img src={logo} alt="Logo" className="bottom-logo" />

      <footer>
        <p>
          © {currentYear} Monailisa. All rights reserved |{" "}
          <Link to="/contact" className="contact-link">
            Contact
          </Link>
        </p>
      </footer>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    if (document.readyState === "complete") handleLoad();
    else window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-text">
          Loading assets<span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<MainContent currentYear={currentYear} />} />
      </Routes>
    </Router>
  );
}

export default App;
